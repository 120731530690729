






















































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { IQuestionCreate, QuestionTypes } from '@/interfaces';

@Component
export default class QuestionsList extends Vue {
  @Prop(Object) public question: IQuestionCreate | undefined;
  @Prop(Number) public questionIndex!: number;

  public questionDialog = false;
  public valid = false;
  public currentQuestion = {
    title: '',
    context: '',
    type: QuestionTypes.binary,
    contracts: ['', '', ''],
  };

  public types = [
    { text: 'Binary question', value: QuestionTypes.binary, bool: false },
    { text: 'Scenario question ', value: QuestionTypes.scenario, bool: false },
    { text: 'Open Ended question ', value: QuestionTypes.open, bool: false },
  ];

  public contracts: string[] = ['', '', ''];

  public created() {
    this.setQuestions();
  }

  public addScenario() {
    this.contracts.push('');
  }

  public addNominalOption() {
    this.contracts.push('');
  }

  public async pushQuestion() {
    if (await this.$validator.validateAll()) {
      const filtered = this.contracts.filter(function (obj) {
        return obj.length;
      });
      const updatedContracts = [
        ...new Set(
          filtered.map((obj) => {
            return obj.trim();
          })
        ),
      ];
      if (
        this.currentQuestion.type == QuestionTypes.binary ||
        (updatedContracts.length > 2 &&
          this.currentQuestion.type == QuestionTypes.scenario) || 
        (updatedContracts.length > 1 &&
          this.currentQuestion.type == QuestionTypes.nominal) || 
        this.currentQuestion.type == QuestionTypes.open
      ) {
        this.currentQuestion.contracts = updatedContracts;
        this.$emit(
          'pushUpdatedQuestion',
          this.currentQuestion,
          this.questionIndex
        );
        this.questionDialog = false;
      } else {
        const field = this.$validator.fields.find({ name: '0' });
        if (field) {
          this.$validator.errors.add({
            field: '0',
            msg: this.currentQuestion.type == QuestionTypes.scenario ?
              'You must have at least 3 unique scenarios. Please fix them to continue' : 
              'You must have at least 2 unique options. Please fix them to continue',
          });
        }
      }
    }
  }

  public async closeWindow() {
    this.questionDialog = false;
    this.resetContracts();
    this.resetQuestion();
    this.setQuestions();
  }

  public setQuestions() {
    if (this.question) {
      this.currentQuestion.title = this.question.title;
      this.currentQuestion.context = this.question.context;
      this.currentQuestion.type = this.question.type;
      this.contracts = [];
      this.question.contracts!.map((obj, index) => {
        this.contracts.push(obj);
      });
    }
  }

  public resetContracts() {
    this.contracts = [];
  }

  public resetQuestion() {
    this.currentQuestion = {
      title: '',
      context: '',
      type: QuestionTypes.binary,
      contracts: ['', '', ''],
    };
  }

  public removeQuestion() {
    this.$emit('deleteQuestion', this.questionIndex);
  }
  get buttonSize() {
    return this.$vuetify.breakpoint.name === 'xs' ? { ['small']: true } : {};
  }
}
