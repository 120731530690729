




































































































import { Component, Vue } from 'vue-property-decorator';
import { ISessionCreate } from '@/interfaces';
import { dispatchCreateSession } from '@/store/sessions/actions';
import { readLatestSession } from '@/store/sessions/getters';
import QuestionCarousel from '@/components/QuestionCarousel.vue';
import { IQuestionCreate, QuestionTypes } from '@/interfaces';
import QuestionsList from '@/components/QuestionsList.vue';

@Component({
  components: {
    'question-carousel': QuestionCarousel,
    'question-list': QuestionsList,
  },
})
export default class CreateStudy extends Vue {
  public valid = false;
  public title = '';
  public description = '';
  public redirectUrl = '';
  public questions: IQuestionCreate[] = [];
  public listReload = 0;

  public types = [
    { text: 'Binary question', value: QuestionTypes.binary, bool: false },
    { text: 'Scenario question ', value: QuestionTypes.scenario, bool: false },
    { text: 'Open-Ended question ', value: QuestionTypes.open, bool: false },
  ];

  public resetSession() {
    this.title = '';
    this.description = '';
    this.redirectUrl = '';
  }
  public async addQuestion(question) {
    this.questions.push(question);
  }

  public removeQuestion(index) {
    this.questions.splice(index, 1);
    this.listReload++;
  }

  public updateQuestion(question, index) {
    this.questions.splice(index, 1);
    this.questions.splice(index, 0, question);
    this.listReload++;
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedSession: ISessionCreate = {
        title: this.title,
        description: this.description.length > 0 ? this.description : undefined,
        redirect_url:
          this.redirectUrl.length > 0 ? this.redirectUrl : undefined,
        questions: this.questions,
      };
      if (this.description) {
        updatedSession.description = this.description;
      }
      await dispatchCreateSession(this.$store, updatedSession);
      this.resetSession();
      const newSession = readLatestSession(this.$store);
      await this.$router.push(`/main/user/view/${newSession.id}`);
    }
  }

  get buttonSize() {
    return this.$vuetify.breakpoint.name === 'xs' ? { ['small']: true } : {};
  }

  get mobile() {
    return this.$vuetify.breakpoint.name === 'xs';
  }
}
